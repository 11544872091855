<template>
  <el-form
    ref="sessionClass"
    :model="sessionClass"
    @submit.prevent.native="submit"
    :validate-on-rule-change="false"
    label-position="right"
    label-width="200px"
  >
    <template v-if="!isEditTest">
      <el-form-item
        label="是否展示在分班頁面"
        :rules="[commonRules.input]"
        prop="course_session_id"
      >
        <el-radio-group v-model="sessionClass.show_for_placement">
          <el-radio-button :label="1">是</el-radio-button>
          <el-radio-button :label="0">否</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="所屬梯次"
        :rules="[commonRules.input]"
        prop="course_session_id"
      >
        <el-select
          style="width:100%"
          :value="sessionClass.course_session_id"
          @change="
            course_session_id => $emit('setSessionClass', { course_session_id })
          "
          placeholder="所屬梯次"
          required
        >
          <el-option
            v-for="course_session in courseSessions"
            :key="course_session.id"
            :label="course_session.title.tw"
            :value="course_session.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="標題" :rules="[commonRules.input]" :prop="'title'">
        <el-input
          :value="sessionClass.title"
          @input="title => $emit('setSessionClass', { title })"
        />
      </el-form-item>
      <el-form-item
        label="上課地址"
        :rules="[commonRules.input]"
        prop="address"
      >
        <el-input
          :value="sessionClass.address"
          @input="address => $emit('setSessionClass', { address })"
        />
      </el-form-item>
      <el-form-item label="授課老師" prop="teacher_user_id">
        <div
          class="teacher"
          :key="teacher.teacher_user_id"
          v-for="(teacher, index) in sessionClass.teachers"
        >
          <template v-if="teacher.action_mode !== 'D'">
            <TeacherSelector
              :value="teacher.teacher_user_id"
              @change="teacher => setSessionClassTeachers(index, teacher.id)"
            />
            <div class="action-icon-without-pointer">
              <el-button
                type="text"
                style="color:red"
                icon="el-icon-circle-close"
                @click="removeTeacher(index)"
              ></el-button>
            </div>
          </template>
        </div>
        <el-button type="success" @click="addTeacher">Add Teacher</el-button>
        <hr />
      </el-form-item>
      <el-form-item
        label="開始日期"
        :rules="[commonRules.input]"
        prop="started_on"
      >
        <el-date-picker
          :value="sessionClass.started_on"
          @input="started_on => $emit('setSessionClass', { started_on })"
          type="date"
          placeholder="日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item
        label="結束日期"
        :rules="[commonRules.input]"
        prop="ended_on"
      >
        <el-date-picker
          :value="sessionClass.ended_on"
          @input="ended_on => $emit('setSessionClass', { ended_on })"
          type="date"
          placeholder="日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item
        label="開始時間"
        :rules="[commonRules.input]"
        prop="time_from"
      >
        <el-time-select
          :value="sessionClass.time_from"
          @input="time_from => $emit('setSessionClass', { time_from })"
          :picker-options="{
            start: '08:00',
            step: '00:15',
            end: '23:30'
          }"
          placeholder="開始時間"
        >
        </el-time-select>
      </el-form-item>
      <el-form-item
        label="結束時間"
        :rules="[commonRules.input]"
        prop="time_to"
      >
        <el-time-select
          :value="sessionClass.time_to"
          @input="time_to => $emit('setSessionClass', { time_to })"
          :picker-options="{
            start: '08:00',
            step: '00:15',
            end: '23:30'
          }"
          placeholder="結束時間"
        >
        </el-time-select>
      </el-form-item>
    </template>
    <el-form-item label="模考樣板">
      <div class="set-test">
        <el-select
          v-if="templates !== undefined"
          filterable
          style="width:100%"
          v-model="template"
          placeholder="(You can choose the tests from an existing section or type in your own.)"
          @change="setTests"
        >
          <el-option-group
            v-if="
              templates.course_sessions && templates.course_sessions.length > 0
            "
            label="梯次模板"
          >
            <el-option
              v-for="(testPaper, index) in templates.course_sessions"
              :key="index + 1"
              :label="testPaper.course_session_title"
              :value="testPaper.course_session_assign_tests"
            >
            </el-option>
          </el-option-group>
          <el-option-group
            v-if="
              templates.session_classes && templates.session_classes.length > 0
            "
            label="小班模板"
          >
            <el-option
              v-for="(testPaper, index) in templates.session_classes"
              :key="index + 1"
              :label="testPaper.title"
              :value="testPaper.session_class_assign_tests"
            >
            </el-option>
          </el-option-group>
        </el-select>
        <div
          v-for="(test, index) in sessionClass.session_class_assign_tests"
          :key="index"
        >
          <hr />
          <h6 class="d-flex" style="align-items:center;">
            <span style="width: 50px;">
              {{ index === 0 ? `分班` : `Test${index}` }}
            </span>
            <span>
              <el-date-picker
                class="pl-20"
                style="width:150px;margin:0 10px"
                v-model="test.exam_date"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="選擇考試日期"
              >
              </el-date-picker>
            </span>
            <span>
              <el-select
                v-model="test.exam.exam_category_id"
                placeholder="選擇考卷類型"
                style="width:200px;margin-right:10px"
              >
                <el-option :value="-1" label="SAT">SAT</el-option>
                <el-option :value="1" label="TOEFL">
                  TOEFL
                </el-option>
                <!-- <el-option :value="5" label="Ivyway線上考試">Ivyway線上考試</el-option> -->
                <!-- <el-option :value="2" label="SAT紙本" :disabled="true">SAT紙本</el-option> -->
                <el-option :value="3" label="ACT" :disabled="true">
                  ACT
                </el-option>
              </el-select>
            </span>
            <!-- TOEFL -->
            <el-select
              v-if="test.exam && test.exam.exam_category_id === 1"
              filterable
              style="width:50%;margin-right:10px"
              v-model="test.exam_id"
              placeholder="選擇考卷"
            >
              <el-option
                v-for="(item, index) in sessionsTestsTOEFL"
                :key="index"
                :label="item.title"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <!-- SAT -->
            <template v-if="test.exam && test.exam.exam_category_id === 2">
              <el-select
                filterable
                style="width:50%;margin-right:10px"
                v-model="test.exam_id"
                placeholder="選擇考卷"
              >
                <el-option
                  v-for="(item, index) in sessionsTestsSAT"
                  :key="index"
                  :label="item.title"
                  :value="item.exam_id"
                >
                </el-option>
              </el-select>
            </template>
            <!-- ACT -->
            <el-select
              v-if="test.exam && test.exam.exam_category_id === 3"
              filterable
              style="width:50%;margin-right:10px"
              v-model="test.exam_id"
              placeholder="選擇考卷"
            >
              <el-option
                v-for="(item, index) in sessionsTestsACT"
                :key="index"
                :label="item.title"
                :value="item.exam_id"
              >
              </el-option>
            </el-select>
            <!-- SATMocks -->
            <template v-if="test.exam && test.exam.exam_category_id === -1">
              <el-select
                filterable
                style="width:50%;margin-right:10px"
                v-model="test.exam_id"
                placeholder="選擇考卷"
              >
                <el-option
                  v-for="(item, index) in filteredOnlineSATPractices"
                  :key="index"
                  :label="getIvywayExamTitle(item.title)"
                  :value="item.id"

                >
                  <span style="float: left">{{ getIvywayExamTitle(item.title) }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px" v-if="isTestAlreadySelected(item.id)">✅ Used</span>
                </el-option>
              </el-select>
              <el-tooltip effect="dark" content="" placement="top">
                <div slot="content" style="width: 200px;">
                  Lock explanation and correct answer: Check this if you want to
                  hide explanations and correct answer from students when they
                  finish the test. If you hide the explanations and correct
                  answer, you can manually reveal it to individual students
                  later.
                </div>
                <el-checkbox
                  class="m-0 mr-2"
                  :value="test.hide_exp_answer"
                  @change="hide_exp_answer => changeHide(test, hide_exp_answer)"
                  :true-label="1"
                  :false-label="0"
                  border
                >
                  <i class="fas fa-lock"></i>
                </el-checkbox>
              </el-tooltip>
              <!-- <el-checkbox
                class="m-0 mr-2"
                v-model="test.hide_answer"
                :true-label="1"
                :false-label="1"
                border
              >
                <i class="fas fa-lock"></i>
              </el-checkbox> -->
            </template>
            <el-button
              type="text"
              style="color:red"
              icon="el-icon-circle-close"
              @click="removeTest(index)"
            ></el-button>
          </h6>
        </div>
        <hr />
        <el-button type="success" @click="addTest">Add Test</el-button>
      </div>
    </el-form-item>
    <hr />
    <el-form-item>
      <el-button type="primary" @click="onSubmit">
        Save 儲存
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import courseApi from "@/apis/course";
import onlineSAT from "@/apis/onlineSAT";
import formMixin from "@/mixins/form";
import { TeacherSelector } from "@/components/selector";

export default {
  components: {
    TeacherSelector
  },

  mixins: [formMixin],

  props: {
    sessionClass: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      sessionsTests: [],
      sessionsTestsSAT: [],
      sessionsTestsACT: [],
      sessionsTestsTOEFL: [],
      sessionsTestsSATPractices: [],
      sessionsTestsOnlineSATPractices: [],
      courseSessions: [],
      testPapers: [],
      templates: {
        course_sessions: [],
        session_classes: []
      },
      template: ""
    };
  },
  async mounted() {
    await this.getAssignTest();
    await this.getTemplates();
    let sessionRes = await courseApi.fetchSessions({
      is_visible: 1
    });
    this.courseSessions = sessionRes.course_sessions;
  },
  computed: {
    isEditTest() {
      if (this.$route.query.edit_test) {
        return 1;
      } else {
        return 0;
      }
    },
    filteredOnlineSATPractices() {
      return this.sessionsTestsOnlineSATPractices;
    }
  },
  methods: {
    changeHide(test, value) {
      test.hide_exp_answer = value;
      test.hide_exp = value;
      test.hide_answer = value;
      
    },
    async getAssignTest() {
      await Promise.all([
        courseApi.getAllExams({
          type: "SAT"
        }),
        courseApi.getTestAdmit(),
        courseApi.getAllExams({
          type: "ACT"
        }),
        courseApi.getAllExams({
          type: "SAT_COMPOSE_PRACTICE"
        }),
        courseApi.getAllExams({
          type: "SATMOCKS"
        })
        // courseApi.getOnlineSATExams({
        //   mark: "new",
        //   type: "compose-practice"
        // })
      ]).then(values => {
        this.sessionsTestsSAT = values[0].exams;
        this.sessionsTestsTOEFL = values[1].exams;
        this.sessionsTestsACT = values[2].exams;
        this.sessionsTestsSATPractices = values[3].exams;
        this.sessionsTestsOnlineSATPractices = values[4].exams;
      });
    },
    async getTemplates() {
      let res = await courseApi.getTemplates();
      this.templates = res;
    },
    setSessionClassTeachers(targetIndex, teacherUserId) {
      const newSessionClassTeachers = this.sessionClass.teachers.map(
        (teacher, index) => {
          if (index === targetIndex) {
            return { ...teacher, teacher_user_id: teacherUserId };
          }
          return teacher;
        }
      );

      this.$emit("setSessionClass", {
        teachers: newSessionClassTeachers,
        teacher_user_id: newSessionClassTeachers[0].teacher_user_id
      });
    },
    addTeacher() {
      this.$emit("setSessionClass", {
        teachers: [...this.sessionClass.teachers, { teacher_user_id: null }]
      });
    },
    removeTeacher(targetIndex) {
      this.$emit("setSessionClass", {
        teachers: this.sessionClass.teachers.filter(
          (_value, index) => targetIndex !== index
        )
      });
    },
    setTests(val) {
      if (val.length > 0) {
        let arr = [];
        val.forEach(element => {
          let test = {
            exam: element.exam
              ? { ...element.exam, exam_category_id: -1 }
              : { exam_category_id: -1 },
            exam_id: element.exam_id,
            is_created: 0
          };
          arr.push(test);
        });
        this.$emit("setSessionClass", { session_class_assign_tests: arr });
      } else {
        this.$emit("setSessionClass", { session_class_assign_tests: [] });
      }
    },
    addTest() {
      let week = this.sessionClass.session_class_assign_tests.length;
      console.log(this.sessionClass);
      console.log(this.sessionClass.session_class_assign_tests);
      this.$emit("setSessionClass", {
        session_class_assign_tests: [
          ...this.sessionClass.session_class_assign_tests,
          {
            exam: {
              exam_category_id: -1
            },
            exam_id: "",
            is_created: 0,
            hide_exp: 0,
            hide_answer: 0,
            hide_exp_answer: 0,
            which_week: week
          }
        ]
      });
    },
    removeTest(targetIndex) {
      this.$emit("setSessionClass", {
        session_class_assign_tests: this.sessionClass.session_class_assign_tests.filter(
          (_value, index) => targetIndex !== index
        )
      });
    },
    isTestAlreadySelected(examId) {
      return this.sessionClass.session_class_assign_tests.some(
        test => test.exam_id === examId && test.exam.exam_category_id === -1
      );
    },
    async onSubmit() {
      const valid = await this.validateForm(this.$refs["sessionClass"]);
      if (!valid) {
        this.$message.error(this.$t("message.form_validation_error"));
        return;
      }

      this.$emit("onSubmit");
    }
  }
};
</script>

<style scoped>
.teacher {
  margin-bottom: 8px;
  display: flex;
}
</style>
