import Vue from "vue";
import axios from "@/common/interceptors.service";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);
export default {
  SATGet(resource, params) {
    return Vue.axios({
      method: "get",
      url: `${process.env.VUE_APP_SAT_BACKEND_URL}/api/${resource}`,
      params: params
    }).catch(error => {
      throw error.response;
    });
  },
  getOnlineSATTests(students) {
    return this.SATGet("ivy-way/user-exams", students);
  }
};
