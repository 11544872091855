<template>
  <div class="container">
    <Breadcrumb>
      <template slot="lastPage">
        {{ $t("pageTitle.createSessionClass") }}
      </template>
    </Breadcrumb>
    <br />
    <div>
      <Heading :content="$t('pageTitle.createSessionClass')"></Heading>
      <hr />
    </div>
    <div>
      <SessionClassForm
        :sessionClass="sessionClass"
        @setSessionClass="setSessionClass"
        @onSubmit="onSubmit"
      />
    </div>
  </div>
</template>

<script>
import courseApi from "@/apis/course";
import formMixin from "@/mixins/form";
import Breadcrumb from "@/components/Breadcrumb";
import SessionClassForm from "@/views/sessionClasses/SessionClassForm";

export default {
  metaInfo() {
    return {
      title: `${this.$t("pageTitle.createSessionClass")} - Ivy-Way Academy`
    };
  },

  components: {
    Breadcrumb,
    SessionClassForm
  },

  mixins: [formMixin],

  props: [],
  data() {
    return {
      sessionClass: {
        show_for_placement: 1,
        course_session_id: "",
        teacher_user_id: null,
        session_class_assign_tests: [],
        teachers: []
      }
    };
  },
  computed: {
    courseSessionId: {
      get() {
        let courseSessionId = "";
        if (this.$route.query.course_session_id) {
          courseSessionId = parseInt(this.$route.query.course_session_id);
        }
        return courseSessionId;
      }
    }
  },
  async mounted() {
    if (this.courseSessionId > 0) {
      this.sessionClass.course_session_id = this.courseSessionId;
    }
  },

  methods: {
    setSessionClass(session_class_assign_tests) {
      if (
        (session_class_assign_tests.session_class_assign_tests?.length || 0) > 0
      ) {
        session_class_assign_tests.session_class_assign_tests.forEach(
          (item, index) => {
            item.which_week = index;
            console.log(index);
          }
        );
      }
      this.sessionClass = {
        ...this.sessionClass,
        ...session_class_assign_tests
      };
    },
    async onSubmit() {
      if (
        this.sessionClass.session_class_assign_tests &&
        this.sessionClass.session_class_assign_tests.length > 0
      ) {
        this.sessionClass.session_class_assign_tests.forEach((arr, index) => {
          if (arr.exam.exam_category_id == -1) {
            //SAT线上
            this.sessionClass.session_class_assign_tests[index]["exam_type"] =
              "App\\Entities\\SatMocks\\Exam";
          } else {
            //ivyway
            this.sessionClass.session_class_assign_tests[index]["exam_type"] =
              "App\\Entities\\Exam";
          }
        });
      }
      courseApi.createSessionClass(this.sessionClass).then(response => {
        this.$message({
          showClose: true,
          message: this.$t("message.create_success"),
          type: "success"
        });
        this.routerPush({
          name: "myClasses"
        }).catch(error => {
          this.$message({
            showClose: true,
            message: this.$t("message.something_went_wrong"),
            type: "error"
          });
        });
      });
    }
  }
};
</script>

<style scoped></style>
